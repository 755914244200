import React from 'react'

import IconArrow from '@/assets/icons/arrows/arrow-blue-white-bg-16x16.svg'

import classes from './Content.module.scss'

import { TABS } from '../../constants'
import Search from '@/components/Search/Search'

type TContent = { id: number }

export default function Content({ id }: TContent) {
  const { title, text, link, search: showSearch } = TABS[id].content

  return (
    <div className={classes.root}>
      <b className={classes.title}>{title}</b>
      <div className={classes.description}>
        <p className={classes.text}>{text}</p>
        {link && (
          <a href={link} className={classes.link}>
            Read more
            <IconArrow />
          </a>
        )}
      </div>
      {showSearch && (
        <div className={classes.search}>
          <Search />
        </div>
      )}
    </div>
  )
}
